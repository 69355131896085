import React from "react";
import NavBar from "../components/NavBar";

const Community = () => {
  return (
    <>
      <head>
        <title>TBP Community</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <link rel="stylesheet" href="assets/css/main.css" />
        <noscript><link rel="stylesheet" href="assets/css/noscript.css" /></noscript>
      </head>
      <body>

        <NavBar /> 


        <div id="wrapper">
          <section class="wrapper style2">
            <div class="inner">
              <h1 class="major">Tau Beta Pi @ UCSD</h1>
              <span class="image fit">
                <img src="images/DSC_0987.JPG" alt="" />
              </span>
              <p>
                The UC San Diego chapter of TBP works to create and network a
                community of engineers across all departments and majors. Our
                goal is to help our members grow into well-rounded engineers who
                honor their Alma Mater.
              </p>
            </div>
          </section>

          <section id="one" class="wrapper style1 spotlights">
            <section>
              <a href="https://www.tbp.org/recruit/recruitHome.cfm" class="image">
                <img src="images/DSC_0814.JPG" height="100%" width="115%" alt="" data-position="25% 25%" />
              </a>

              <div class="content">
                <div class="inner">
                  <h2>We Are Tau Beta Pi</h2>
                  <p>
                    Tau Beta Pi is the premier engineering honor society in the
                    United States and one of the top three honor societies in
                    the nation. Tau Beta Pi @ UCSD is just one of many Tau Beta
                    Pi chapters across the nation. Our Officers keep our chapter
                    active and help promote the future growth of our chapter by
                    nurturing our integrity, character, and charitability
                    through hosting events.
                  </p>
                  <ul class="actions">
                    <li>
                      <a
                        href="https://www.tbp.org/recruit/recruitHome.cfm"
                        class="button"
                      >
                        Explore National
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>

          <section class="wrapper style2">
            <div class="inner">
              <h1>Meet the Team</h1>
              <div class="box alt">
                <div class="row gtr-uniform">
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/malia.png" alt="" />
                      Malia Monge
                      <br />
                      <b>President</b> <br />
                      <a href="mailto:mmonge@ucsd.edu">mmonge@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/will.jpg" alt="" />
                      William Zhang <br />
                      <b>President</b> <br />
                      <a href="mailto:wiz002@ucsd.edu">wiz002@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/praveen.jpg" alt="" />
                      Praveen Swaminathan
                      <br />
                      <b>VP External: Engineering</b> <br />
                      <a href="mailto:pswaminathan@ucsd.edu">pswaminathan@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/TBPNationalLogo.jpg" alt="" />
                      Aniruddh Krovvidi
                      <br />
                      <b>VP External: Technology</b> <br />
                      <a href="mailto:akrovvidi@ucsd.edu">akrovvidi@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/jade.jpg" alt="" />
                      Jade Chng <br />
                      <b>VP Internal</b> <br />
                      <a href="mailto:jchng@ucsd.edu">jchng@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/max.png" alt="" />
                      Max Vroemen
                      <br />
                      <b>VP Finance</b> <br />
                      <a href="mailto:mvroemen@ucsd.edu">mvroemen@ucsd.edu</a>
                    </span>
                  </div>
                
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/evelyn.jpg" alt="" />
                      Evelyn Doan <br />
                      <b>Secretary/Historian</b> <br />
                      <a href="mailto:etdoan@ucsd.edu">etdoan@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/TBPNationalLogo.jpg" alt="" />
                      Melina Dimitropoulou <br />
                      <b>Social Chair</b> <br />
                      <a href="mailto:mdimitropoulouka@ucsd.edu">mdimitropoulouka@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/radha.png" alt="" />
                      Radha Ambe <br />
                      <b>Social Chair</b> <br />
                      <a href="mailto:rambe@ucsd.edu">rambe@ucsd.edu</a>
                    </span>
                  </div>

                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/nickO.png" alt="" />
                      Nicholas Ormsby
                      <br />
                      <b>Technology Chair</b> <br />
                      <a href="mailto:nlormsby@ucsd.edu">nlormsby@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/eric.jpg" alt="" />
                      Eric Zheng
                      <br />
                      <b>Technology Chair</b> <br />
                      <a href="mailto:erzheng@ucsd.edu">erzheng@ucsd.edu</a>
                    </span>
                  </div>
                  
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/alex.png" alt="" />
                      Alex Lorang
                      <br />
                      <b>Outreach and Service Chair</b> <br />
                      <a href="mailto:alorang@ucsd.edu">alorang@ucsd.edu</a>
                    </span>
                  </div>
                  
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/nick.jpg" alt="" />
                      Nick Monell
                      <br />
                      <b>BENG Representative</b> <br />
                      <a href="mailto:nmonell@ucsd.edu">nmonell@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images/headshots_24_25/nishant.png" alt="" />
                      Nishant Sharma
                      <br />
                      <b>BENG Representative</b> <br />
                      <a href="mailto:n8sharma@ucsd.edu">n8sharma@ucsd.edu</a>
                    </span>
                  </div>

                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/jaehoon.jpg" alt="" />
                      Jaehoon Kim
                      <br/>
                      <b>CSE Representative</b> <br />
                      <a href="mailto:jjk015@ucsd.edu">jjk015@ucsd.edu</a>
                    </span>
                  </div>

                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/xane.png" alt="" />
                      Alexander Hunter
                      <br/>
                      <b>MAE Representative</b> <br />
                      <a href="mailto:a3hunter@ucsd.edu">a3hunter@ucsd.edu</a>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/asa.jpg" alt="" />
                      Asa Alstead
                      <br/>
                      <b>SE Representative</b> <br />
                      <a href="mailto:aalstead@uccsd.edu">aalstead@uccsd.edu</a>
                    </span>
                  </div>
                  
                  <div class="col-4">
                    <span class="image fit">
                      <img src="images//headshots_24_25/evan.png" alt="" />
                      Evan Yu
                      <br />
                      <b>NANO Representative</b> <br />
                      <a href="mailto:evyu@ucsd.edu">evyu@ucsd.edu</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="two" class="wrapper style1 fade-up">
            <div class="inner">
              <h2>What We Do</h2>
              <div class="features">
                <section>
                  <span class="icon major fa-code"></span>
                  <h3>Professional and Academic Events</h3>
                  <p>
                    We hold industry info sessions with companies like Microsoft
                    and Facebook, professional development workshops, and mixers
                    with professors from the engineering departments to help our
                    candidates and members get their dream positions.
                  </p>
                </section>
                <section>
                  <span class="icon major fa-cog"></span>
                  <h3>Community Service and Outreach</h3>
                  <p>
                    We try to give back to our community and inspure future
                    engineers through our community service and outreach events
                    including our collaborations with Nova Spero, our environmental clean-ups, and our trips to
                    orphanages in Tijuana.
                  </p>
                </section>
                <section>
                  <span class="icon major fa-lock"></span>
                  <h3>Social Events</h3>
                  <p>
                    We hold board game nights and pool as well as other fun
                    events like hiking, laser tag, and ice skating to destress
                    and mingle. We also have an annual banquet and bonfire to
                    celebrate the end of the year.
                  </p>
                </section>
                {/* Not doing this rn - Vincent
                <section>
                  <span class="icon major fa-chain"></span>
                  <h3>House System</h3>
                  <p>
                    Our House System provides a more intimate and personal
                    setting for our candidates through the initiation process.
                    We have three houses: Red, Green, and Blue House; which
                    compete during each initiation cycle to earn a reward.{" "}
                  </p>
                </section> */}

                {/* Maybe add Big/Little program? As its own secction or replace Pi Day? - Vincent*/}
                <section>
                  <span class="icon major fa-diamond"></span>
                  <h3>Pi Day</h3>
                  <p>
                    Every year, we hold a celebration for Pi Day where we give
                    out free pizza and pies along with other Pi themed
                    festivities, such as our Pie Drop, pi-nata, cornholes, and
                    Pi Trivia! Help prepare this event by joining the Pi Day
                    Committee.
                  </p>
                </section>
                {/* Not doing this either -Vincent
                <section>
                  <span class="icon major fa-chain"></span>
                  <h3>Family System</h3>
                  <p>
                    In addition to houses, we have a family system to provide
                    candidates with a direct point of contact and opportunities
                    to get to know other candidates and members.
                  </p>
                </section> */}
              </div>
            </div>
          </section>
          <section id="four" class="wrapper style2 spotlights">
            <section>
              <div class="image">
                <img src="images/DSC_0154.JPG" height="100%" width="110%" alt="" data-position="25% 25%" />
              </div>
              <div class="content">
                <div class="inner">
                  <h2>Our Bent</h2>
                  <p>
                    The official badge of the Association is a watch key in the
                    form of the bent of a trestle, engraved on the reverse side
                    with the member's name, chapter, and class. The word key
                    describes the insignia of many organizations. It comes from
                    the fact that it was first designed to, in the late
                    eighteenth century, to include a pocketwatch winding
                    feature, hence key. The bottom stem, added to the basic
                    insignia, had a tapered square hole fitting the common sizes
                    of watch-winding shafts. The top stem and ring were added so
                    that the key could be worn as a pendant from a chain, rather
                    than as a pin or badge, thus easily used to wind watches.
                    The stem-winder watch was introduced in the late nineteenth
                    century, it replaced the key-winder. But the insignia key
                    remained, although with a vestigial hole now round for
                    manufacturing ease and economy. On February 21, 2001 TBP –
                    CA Psi erected its Bent statue outside EBU I.
                  </p>
                </div>
                <ul class="actions">
                  <li>
                    <a
                      href="http://www.phkimpel.us/Bent-O-Rama/"
                      class="button"
                    >
                      Explore Other Bents
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </section>

          <section id="five" class="wrapper style1 spotlights">
            <section>
              <div class="image" >
                <img
                  src="images/AnselAward.JPG"
                  height="100%" width="110%"
                  alt=""
                  class="center"
                  data-position="center center"
                />
              </div>
              <div class="content">
                <div class="inner">
                  <h2>Our Accomplishments</h2>
                  <p>
                    The California Psi Chapter at UC San Diego was established
                    in 1994. Currently at about 80 current undergraduate members
                    and over 1,300 alumni members, both the size of membership
                    and activities over the past several years has increased
                    dramatically. In both 2006-2007 and 2009-2010, the
                    California Psi chapter was selected from the 234 and 236
                    active Tau Beta Pi chapters, respectively, to be honored
                    with the R. C. Matthews Most Outstanding Chapter Award. This
                    award recognizes high-grade work by chapters in both routine
                    and special affairs. The award is based on how well chapter
                    service projects fulfill the objectives of Tau Beta Pi and
                    on the quality and promptness of chapter reports to the
                    Headquarters.This is quite an accomplishment and we intend
                    to maintain these high standards in all of our work this
                    school year.
                  </p>
                </div>
              </div>
            </section>
          </section>

          <footer id="footer" class="wrapper style2">
            <div class="inner">
              <ul class="menu">
                <li>&copy; Tau Beta Pi California Psi. All rights reserved.</li>
                <li>
                  Design: <a href="http://html5up.net">HTML5 UP</a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </body>
    </>
  );
};

export default Community;
